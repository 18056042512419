var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.item,
      title: _vm.model && _vm.item.id ? "Atualizar status" : "Cadastrar status",
      icon: "mdi-alert-circle",
      show: _vm.dialog,
      maxWidth: "40%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.item = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-row",
              { staticClass: "mt-1" },
              [
                _vm.message
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              outlined: "",
                              color: "warning",
                              prominent: "",
                              border: "left"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.message) + " ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Nome*",
                    md: "12",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.name
                  },
                  model: {
                    value: _vm.item.name,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "name", $$v)
                    },
                    expression: "item.name"
                  }
                }),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Icone",
                    md: "6",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.icon
                  },
                  model: {
                    value: _vm.item.icon,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "icon", $$v)
                    },
                    expression: "item.icon"
                  }
                }),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Cor",
                    md: "4",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.color
                  },
                  model: {
                    value: _vm.item.color,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "color", $$v)
                    },
                    expression: "item.color"
                  }
                }),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Ordem",
                    md: "2",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.order
                  },
                  model: {
                    value: _vm.item.order,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "order", $$v)
                    },
                    expression: "item.order"
                  }
                })
              ],
              1
            ),
            _c(
              "v-card",
              { staticClass: "mt-4", attrs: { outlined: "" } },
              [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center", justify: "center" } },
                      [
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c("p", { staticClass: "text-center" }, [
                            _vm._v(" Etapa do status ")
                          ])
                        ]),
                        _c(
                          "v-btn-toggle",
                          {
                            attrs: { dense: "", group: "" },
                            model: {
                              value: _vm.item.status_step,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "status_step", $$v)
                              },
                              expression: "item.status_step"
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "px-4",
                                attrs: { value: "1", large: "", elevation: "2" }
                              },
                              [
                                _vm._v(" Início "),
                                _c("v-icon", { attrs: { right: "" } }, [
                                  _vm._v("mdi-ray-start")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "px-4",
                                attrs: { value: "2", large: "", elevation: "2" }
                              },
                              [
                                _vm._v(" Em Progresso "),
                                _c("v-icon", { attrs: { right: "" } }, [
                                  _vm._v("mdi-ray-vertex")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "px-4",
                                attrs: { value: "3", large: "", elevation: "2" }
                              },
                              [
                                _vm._v(" Finalização "),
                                _c("v-icon", { attrs: { right: "" } }, [
                                  _vm._v("mdi-ray-end")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "px-4",
                                attrs: { value: "4", large: "", elevation: "2" }
                              },
                              [
                                _vm._v(" Cancelamento "),
                                _c("v-icon", { attrs: { right: "" } }, [
                                  _vm._v("mdi-cancel")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }